import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationRO from "./assets/locales/ro/translation.json";
import translationEN from "./assets/locales/en/translation.json";
import translationFR from "./assets/locales/fr/translation.json";
import translationDE from "./assets/locales/de/translation.json";
import translationIT from "./assets/locales/it/translation.json";
import translationHU from "./assets/locales/hu/translation.json";

const fallbackLng = "ro";
const availableLanguages = ["ro", "en", "fr","de","it","hu"];

const resources = {
    ro: {
        translation: translationRO
    },
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    },
    de: {
        translation: translationDE
    },
    it: {
        translation: translationIT
    },
    hu: {
        translation: translationHU
    }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: fallbackLng,
    fallbackLng: fallbackLng,

    detection: {
      checkWhitelist: true
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
